import CreatePost from '../Components/CreatePost';
import { useEffect, useState } from 'react';
import ForumBlogPost from '../Components/ForumBlogPost';
import APIEnv from '../Components/APIEnv';
import axios from 'axios';
import VerifyUser from '../Components/VerifyUser';

export default function FasadBloggen() {

    const [token, setToken] = useState(null)
    const [blogEntriesRight, setRightBlogEntries] = useState([]);
    const [blogEntriesLeft, setLeftBlogEntries] = useState([]);
    const [myBlogs, setMyBlogs] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [currentPage, setCurrentPage] = useState('fasad');
    const [visibleLeftPosts, setVisibleLeftPosts] = useState(4);
    const [visibleRightPosts, setVisibleRightPosts] = useState(4);

    const loadMoreLeftPosts = () => {
        setVisibleLeftPosts((prevVisiblePosts) => prevVisiblePosts + 4); // Load 4 more posts
    };
    const loadMoreRightPosts = () => {
        setVisibleRightPosts((prevVisiblePosts) => prevVisiblePosts + 4); // Load 4 more posts
    };

    useEffect(() => {
        const fetchData = async () => {
            let attempts = 5;
            let current = 0;
            while (attempts > current) {
                try {
                    const resRight = await axios.post(`${APIEnv.apiUrl}/api/pagecontent`, {
                        page: "fasadRight",
                        content: "",
                        section: 0
                    });
                    const blogEntriesRight = resRight.data.matchingBlogEntries;
                    setRightBlogEntries(blogEntriesRight.reverse());
                    const resLeft = await axios.post(`${APIEnv.apiUrl}/api/pagecontent`, {
                        page: "fasadLeft",
                        content: "",
                        section: 0
                    });
                    const blogEntriesLeft = resLeft.data.matchingBlogEntries;
                    setLeftBlogEntries(blogEntriesLeft.reverse());
                    break;
                } catch (err) {
                    console.log(err);
                }
            }
        };
        const fetchMyBlogs = async (newToken) => {
            try {
                const res = await axios.get(`${APIEnv.apiUrl}/blogs/getmyposts?salt=${newToken}`);
                setMyBlogs(res.data);

                setLoading(false);
            } catch (e) {
                console.log("ERRR", e);
            }
        };
        VerifyUser(true).then((res) => {
            setToken(res.token)
            if (!res.token) {
                window.location.href = "/"
            }
            return res.token
        }).then((newToken) => {
            fetchData()
            return newToken
        }).then((ultToken) => {
            fetchMyBlogs(ultToken)
        });
    }, [token]);

    const newPostPop = (direction) => {
        setCurrentPage(direction);
        document.getElementsByClassName("createPostContainer")[0].style.opacity = 1;
        document.getElementsByClassName("createPostContainer")[0].style.pointerEvents = "unset";
        document.getElementsByClassName("createPostContainer")[0].className = document.getElementsByClassName("createPostContainer")[0].className + " text-center"
    };
    const isPhone = /iPhone|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const switchView = (direction) => {
        const leftPanel = document.getElementsByClassName("leftContainer")[0];
        const rightPanel = document.getElementsByClassName("rightContainer")[0];
        if(direction === "left"){
            leftPanel.style.display = 'block'
            rightPanel.style.display = 'none'
        } else {
            rightPanel.style.display = 'block'
            leftPanel.style.display = 'none'
        }
    };

    const downloadProtokoll = async () => {
        try {
            const res = await axios.get(`${APIEnv.apiUrl}/fasadprotokoll`, {
                responseType: 'blob' // important
            });
            
            // Create a blob link to download the file
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `fasadprotokoll.pdf`); // Ensure the file is downloaded as a .pdf
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };
    return (
        <>
            {loading ? <></> : <>
                <CreatePost currentPage={currentPage} token={token} />
            </>}

            {token ? (
                <div className="forumContainer pb-4">
                    {/* image row */}
                    <div className="col-12">
                        <div className="row imageRow">
                            <img src='/images/april/Header fasadbloggen.jpg' />
                        </div>
                    </div>
                    <div className="mt-3 text-center p-2" style={{ fontSize: "15px" }}>
                        Med anledning av den framtida fasadrenoveringen har vi tagit fram fasdbloggen där Zeev Bohbot kontinuerligt informerar<br></br>
                         om status i projektet och där ni medlemmar också kan ställa frågor i frågespalten här nedan.
                    </div>
                    <div className="mt-3 text-center">
                        Protokoll från extrastämman den 28 Augusti
                        <i className='fa fa-download m-3' onClick={downloadProtokoll}></i>
                    </div>
                    {isPhone ? <>
                        <div className='d-flex justify-content-center'>
                            <div className='fasadChoose m-4 mb-0' onClick={() => switchView("left")}>Ny information</div>
                            <div className='fasadChoose m-4 mb-0 p-4 pt-1 pb-0' onClick={() => switchView("right")}>Frågor</div>
                        </div>
                        <div className='leftContainer col-12'>
                        <div className='d-flex justify-content-center'>
                                {['S5WV5NuA2gbUCDJ3x9LIc8IcbyEqJNA6', '88Pz39wi5jAWwcXm8K9skFUyAXLzHX6v', '0bGfJa8Lq98tQMNKy46DVLWYgKrAokxO'].includes(token) ?
                                    <div className="createPostFasad container-fluid text-center pt-1 mt-3" style={{ borderRadius: "5px" }} onClick={() => newPostPop('fasadLeft')}>Status/inlägg&nbsp;&nbsp;&nbsp;
                                        <i className="fa fa-plus"></i>
                                    </div> :
                                    <h3 className='m-5 mb-0 mt-4'>
                                        Vad händer nu?
                                    </h3>
                                }
                            </div>
                            {loading ? (
                                <div>Loading...</div> // Render a loading indicator while data is being fetched
                            ) : (
                                blogEntriesLeft.slice(0, visibleLeftPosts).map((blog, index) => (
                                    <div key={blog.id}>
                                        <ForumBlogPost
                                            key={blog.id}
                                            index={index}
                                            id={blog.id}
                                            title={blog.title}
                                            content={blog.content}
                                            poster={blog.posterName}
                                            date={blog.dateposted.split("T")[0]}
                                            myblogs={myBlogs}
                                            token={token}
                                            numberlikes={blog.likes}
                                            likes={blog.likedBy}
                                            page="fasadLeft"
                                        />
                                    </div>
                                ))
                            )}
                            {visibleLeftPosts < blogEntriesLeft.length && (
                                <div className='d-flex justify-content-center mt-3'>
                                    <button onClick={loadMoreLeftPosts} className="load-more-btn">
                                        Ladda fler inlägg
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className='rightContainer col-12'>
                        {token !== "88Pz39wi5jAWwcXm8K9skFUyAXLzHX6v" ? <>
                                <div className='row'>
                                    <div className="createPostFasad container-fluid text-center pt-1 mt-3" style={{ borderRadius: "5px" }} onClick={() => newPostPop('fasadRight')}>Ny fråga&nbsp;&nbsp;&nbsp;
                                        <i className="fa fa-plus"></i>
                                    </div>
                                </div>
                            </> : <><div className='mt-3'>&nbsp;</div></>}
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                blogEntriesRight.slice(0, visibleRightPosts).map((blog, index) => (
                                    <ForumBlogPost
                                        key={blog.id}
                                        index={index}
                                        id={blog.id}
                                        title={blog.title}
                                        content={blog.content}
                                        poster={blog.posterName}
                                        date={blog.dateposted.split("T")[0]}
                                        myblogs={myBlogs}
                                        token={token}
                                        numberlikes={blog.likes}
                                        likes={blog.likedBy}
                                        page="fasadRight"
                                    />
                                ))

                            )}
                            {visibleRightPosts < blogEntriesRight.length && (
                                <div className='d-flex justify-content-center mt-3'>
                                    <button onClick={loadMoreRightPosts} className="load-more-btn">
                                        Ladda fler frågor
                                    </button>
                                </div>
                            )}
                        </div>
                    </> : <>
                    <div className='row mt-3'>
                        <div className='col-6'>
                            <div className='d-flex justify-content-center'>
                                {['S5WV5NuA2gbUCDJ3x9LIc8IcbyEqJNA6', '88Pz39wi5jAWwcXm8K9skFUyAXLzHX6v', '0bGfJa8Lq98tQMNKy46DVLWYgKrAokxO'].includes(token) ?
                                    <div className="createPostFasad container-fluid text-center pt-1 mt-3" style={{ borderRadius: "5px" }} onClick={() => newPostPop('fasadLeft')}>Status/inlägg&nbsp;&nbsp;&nbsp;
                                        <i className="fa fa-plus"></i>
                                    </div> :
                                    <h3 className='m-5 mb-0 mt-2'>
                                        Vad händer nu?
                                    </h3>
                                }
                            </div>
                            {loading ? (
                                <div>Loading...</div> // Render a loading indicator while data is being fetched
                            ) : (
                                blogEntriesLeft.slice(0, visibleLeftPosts).map((blog, index) => (
                                    <div key={blog.id}>
                                        <ForumBlogPost
                                            key={blog.id}
                                            index={index}
                                            id={blog.id}
                                            title={blog.title}
                                            content={blog.content}
                                            poster={blog.posterName}
                                            date={blog.dateposted.split("T")[0]}
                                            myblogs={myBlogs}
                                            token={token}
                                            numberlikes={blog.likes}
                                            likes={blog.likedBy}
                                            page="fasadLeft"
                                        />
                                    </div>
                                ))
                            )}
                            {visibleLeftPosts < blogEntriesLeft.length && (
                                <div className='d-flex justify-content-center mt-3'>
                                    <button onClick={loadMoreLeftPosts} className="load-more-btn">
                                        Ladda fler inlägg
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className='col-6'>
                            {token !== "88Pz39wi5jAWwcXm8K9skFUyAXLzHX6v" ? <>
                                <div className='row'>
                                    <div className="createPostFasad container-fluid text-center pt-1 mt-3" style={{ borderRadius: "5px" }} onClick={() => newPostPop('fasadRight')}>Ny fråga&nbsp;&nbsp;&nbsp;
                                        <i className="fa fa-plus"></i>
                                    </div>
                                </div>
                            </> : <><div className='mt-3'>&nbsp;</div></>}
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                blogEntriesRight.slice(0, visibleRightPosts).map((blog, index) => (
                                    <ForumBlogPost
                                        key={blog.id}
                                        index={index}
                                        id={blog.id}
                                        title={blog.title}
                                        content={blog.content}
                                        poster={blog.posterName}
                                        date={blog.dateposted.split("T")[0]}
                                        myblogs={myBlogs}
                                        token={token}
                                        numberlikes={blog.likes}
                                        likes={blog.likedBy}
                                        page="fasadRight"
                                    />
                                ))

                            )}
                            {visibleRightPosts < blogEntriesRight.length && (
                                <div className='d-flex justify-content-center mt-3'>
                                    <button onClick={loadMoreRightPosts} className="load-more-btn">
                                        Ladda fler inlägg
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    </>}
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
}
